<template>
  <div>
    <div class="banner">
      <el-carousel height="100%">
        <el-carousel-item v-for="item in banners" :key="item">
          <div class="img" :style="'background-image:url('+ item +')'"></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="block-1 container">
      <h4 class="flex flex-align-center">
        <div class="circle"></div>成品市场
      </h4>
      <div class="item-main flex">
        <div class="item-left">
          <div class="tabs">
            <div
              v-for="(item, i) in cpsc.list"
              :key="i"
              class="tab-item"
              :class="{ active: cpsc.activeIndex === i }"
              @click="changeTab(i)"
            >{{ item.name }}</div>
          </div>
        </div>
        <div class="item-right">
          <div class="tab-wrap flex">
            <img v-for="(item, i) in cpsc.list[cpsc.activeIndex].imgs" :key="i" :src="item" />
          </div>
        </div>
      </div>
    </div>
    <div class="block-2 container">
      <h4 class="flex flex-align-center">
        <div class="circle"></div>需求广场
      </h4>
      <div class="table">
        <div class="thead">
          <div class="tr">
            <div class="th" style="flex: 0 0 240px;">产品名称</div>
            <div class="th">需求名称</div>
            <div class="th">需求量</div>
            <div class="th">发布时间</div>
            <div class="th">有效时间</div>
            <div class="th" style="flex: 0 0 240px;">期待报价范围</div>
            <div class="th"></div>
          </div>
        </div>
        <div class="tbody">
          <vueSeamless :data="xqgc" class="seamless-warp">
            <div class="tr" v-for="(item, i) in xqgc" :key="i">
              <div class="td" style="flex: 0 0 240px;">
                <div class="td-inner flex flex-pack-center">
                  <img :src="item.img" />
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
              <div class="td">
                <div class="td-inner">
                  <div class="request-name">{{ item.rqname }}</div>
                </div>
              </div>
              <div class="td">
                <div class="td-inner">
                  <div class="request-amount">
                    {{ item.rqamount }}
                    <div>套/单次</div>
                  </div>
                </div>
              </div>
              <div class="td">
                <div class="td-inner">
                  <div class="time">{{ item.starttime }}</div>
                </div>
              </div>
              <div class="td">
                <div class="td-inner">
                  <div class="time">{{ item.endtime }}</div>
                </div>
              </div>
              <div class="td" style="flex: 0 0 240px;">
                <div class="td-inner">
                  <div class="price">
                    {{ item.price }}
                    <div>件</div>
                  </div>
                </div>
              </div>
              <div class="td">
                <div class="td-inner flex flex-align-center flex-pack-center">
                  <div class="btn">去回应</div>
                </div>
              </div>
            </div>
          </vueSeamless>
        </div>
      </div>
    </div>
    <div class="block-3 container">
      <div class="top-bar flex flex-justify-between">
        <el-select v-model="selectedFactory" placeholder="找工厂">
          <el-option v-for="(item, i) in options" :key="i" :label="item" :value="item"></el-option>
        </el-select>
        <el-input placeholder="加工属性  工艺属性 地区" suffix-icon="el-icon-search" v-model="searchTime"></el-input>
      </div>
      <div class="list">
        <div v-for="(item, i) in factoryList" :key="i" class="item-block">
          <div class="item-top flex flex-justify-between">
            <div class="flex">
              <img :src="item.logo" />
              <div class="img-right">
                <div class="name">{{ item.name }}</div>
                <div class="label-wrap flex">
                  <label
                    v-for="(n, j) in item.labels"
                    :key="j"
                    :class="'color-' + randomInt(5)"
                  >{{ n }}</label>
                </div>
              </div>
            </div>
            <div class="flex flex-align-center">
              <img :src="item.league.logo" />
              {{ item.league.name }}
            </div>
          </div>
          <div class="item-mid flex">
            <div class="feature-item flex flex-align-center">
              <div class="name">加工属性</div>
              <div class="label-wrap">
                <label v-for="(n, j) in item.jgsx" :key="j">{{ n }}</label>
                <label v-if="item.jgsx.length === 0">-</label>
              </div>
            </div>
            <div class="feature-item flex flex-align-center">
              <div class="name">主加工木料</div>
              <div class="label-wrap">
                <label v-for="(n, j) in item.zjgml" :key="j">{{ n }}</label>
                <label v-if="item.zjgml.length === 0">-</label>
              </div>
            </div>
            <div class="feature-item flex flex-align-center">
              <div class="name">主打产品</div>
              <div class="label-wrap">
                <label v-for="(n, j) in item.zdcp" :key="j">{{ n }}</label>
                <label v-if="item.zdcp.length === 0">-</label>
              </div>
            </div>
            <div class="feature-item flex flex-align-center">
              <div class="name">外协</div>
              <div class="label-wrap flex flex-align-center">
                <label v-for="(n, j) in item.wx" :key="j">{{ n }}</label>
                <label v-if="item.wx.length === 0">-</label>
              </div>
            </div>
          </div>
          <div class="item-btm flex">
            <div class="img-wrap">
              <el-carousel
                ref="carousel"
                :autoplay="false"
                indicator-position="none"
                arrow="never"
                height="100%"
              >
                <el-carousel-item v-for="(n, j) in item.imgs" :key="j">
                  <img :src="n" />
                </el-carousel-item>
              </el-carousel>
              <div class="prev" @click="changeImgIndex(i)">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="next" @click="changeImgIndex(i, true)">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="table">
              <div class="flex">
                <div>主要产品</div>
                <div>{{ item.products }}</div>
              </div>
              <div class="flex">
                <div>区域</div>
                <div>{{ item.region }}</div>
                <div>企业类型</div>
                <div>{{ item.type }}</div>
              </div>
              <div class="flex">
                <div>工厂面积</div>
                <div>{{ item.area }}</div>
                <div>雇员数量</div>
                <div>{{ item.employee }}</div>
              </div>
              <div class="flex">
                <div>总产值</div>
                <div>{{ item.total }}</div>
                <div>资质认证</div>
                <div>{{ item.auth }}</div>
              </div>
              <div class="flex">
                <div>生产设备</div>
                <div>{{ item.prodEquip }}</div>
                <div>检测设备</div>
                <div>{{ item.testEquip }}</div>
              </div>
            </div>
            <div class="load-wrap flex">
              <div class="load-item">
                <div class="tit">本月产能总负载</div>
                <div class="load">{{ item.load }}</div>
              </div>
              <div class="load-item">
                <div class="tit">下月产能负载</div>
                <div class="load">{{ item.nextMonthLoad }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { STATIC_URL_PRE } from '@/config';
import echarts from "echarts";
import "echarts-liquidfill";
import vueSeamless from "vue-seamless-scroll";
export default {
  components: {
    vueSeamless
  },
  data() {
    return {
      banners: [
        STATIC_URL_PRE+"design/home%20page/banner-ygc-1.jpg",
        STATIC_URL_PRE+"design/home%20page/banner-ygc-2.jpg",
        STATIC_URL_PRE+"design/home%20page/banner-ygc-3.jpg",
      ],
      cpsc: {
        activeIndex: 0,
        list: [
          {
            name: "橡木",
            imgs: [
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu1.png"),
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu2.png"),
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu3.png"),
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu4.png"),
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu5.png"),
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu6.png"),
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu7.png"),
              require("../../assets/images/home/cloudWorks/xiangmu/xiangmu8.png")
            ]
          },
          {
            name: "乌金木",
            imgs: [
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu1.jpg"),
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu2.jpg"),
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu3.jpg"),
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu4.jpg"),
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu5.jpg"),
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu6.jpg"),
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu7.jpg"),
              require("../../assets/images/home/cloudWorks/wujinmu/wujinmu8.jpg")
            ]
          },
          {
            name: "胡桃木",
            imgs: [
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu1.jpg"),
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu2.jpg"),
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu3.jpg"),
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu4.jpg"),
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu5.jpg"),
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu6.jpg"),
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu7.jpg"),
              require("../../assets/images/home/cloudWorks/hutaomu/hutaomu8.jpg")
            ]
          }
        ]
      },
      xqgc: [
        {
          img: require("../../assets/images/home/xuqiuguangchang1.png"),
          name: "北欧实木床进口橡木欧式大床",
          rqname: "找工厂定制",
          rqamount: "40",
          rqunit: "套/单次",
          starttime: "2020–04-15",
          endtime: "2020–05-15",
          price: "¥800.00-1,300.00"
        },
        {
          img: require("../../assets/images/home/xuqiuguangchang2.png"),
          name: "现代德式轻奢长茶几",
          rqname: "设计需求",
          rqamount: "200",
          rqunit: "套/每月",
          starttime: "2020–04-20",
          endtime: "2020-06-15",
          price: "¥4,500.00-7,000.00"
        },
        {
          img: require("../../assets/images/home/xuqiuguangchang3.png"),
          name: "北欧简约现代温馨实木大床",
          rqname: "找搭配床头柜",
          rqamount: "500",
          rqunit: "套/每月",
          starttime: "2020–04-21",
          endtime: "2020–06-10",
          price: "¥200.00-400.00"
        },
        {
          img: require("../../assets/images/home/xuqiuguangchang1.png"),
          name: "现代中式进口柚木组合电视柜",
          rqname: "打样",
          rqamount: "54",
          rqunit: "套/单次",
          starttime: "2020–02-20",
          endtime: "2020–02-20",
          price: "¥1,500.00-2,000.00"
        },
        {
          img: require("../../assets/images/home/xuqiuguangchang5.png"),
          name: "进口全实木女孩女生双层子母床 ",
          rqname: "找配套书桌",
          rqamount: "40",
          rqunit: "套/每月",
          starttime: "2020–04-23",
          endtime: "2020–06-08",
          price: "¥500.00-1,100.00"
        },
        {
          img: require("../../assets/images/home/xuqiuguangchang6.png"),
          name: "酒店大床 ",
          rqname: "工程单定制",
          rqamount: "500",
          rqunit: "套/每次",
          starttime: "2020–04-23",
          endtime: "2020–08-08",
          price: "¥1,100.00-1,300.00"
        },
        {
          img: require("../../assets/images/home/xuqiuguangchang7.png"),
          name: "医院用床 ",
          rqname: "工程单定制",
          rqamount: "700",
          rqunit: "套/每次",
          starttime: "2020–04-25",
          endtime: "2020–09-16",
          price: "¥800.00-1,100.00"
        }
      ],
      options: [1, 2, 3],
      selectedFactory: null,
      searchTime: "",
      factoryList: [
        {
          name: "江西飞鑫家具有限公司",
          logo: require("../../assets/images/home/cloudWorks/feixinjiaju/feixinlogo.png"),
          labels: ["支持分期", "支持退换", "单件发货"],
          league: {
            logo: require("../../assets/images/home/leaguelogo.png"),
            name: "南康家具品牌联盟成员"
          },
          jgsx: ["自有品牌", "订单加工"],
          zjgml: ["橡胶木"],
          zdcp: ["软体床床", "布艺沙发"],
          wx: [],
          imgs: [
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju1.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju2.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju3.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju4.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju9.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju6.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju7.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju8.jpg"),
            require("../../assets/images/home/cloudWorks/feixinjiaju/feixinjiaju10.jpg")
          ],
          products: "布艺沙发、软体床",
          region: "中国，江西",
          area: "6000m",
          total: "3100万",
          prodEquip: "60台",
          type: "家具经营部",
          employee: "80人",
          auth: "—",
          testEquip: "—/—",
          load: 0.96,
          nextMonthLoad: 0.92
        },
        {
          name: "江西自由王国家具有限公司",
          logo: require("../../assets/images/home/ziyouwangguologo.png"),
          labels: ["环保认证", "准时交货", "排产信息可查"],
          league: {
            logo: require("../../assets/images/home/leaguelogo.png"),
            name: "南康家具品牌联盟成员"
          },
          jgsx: ["外贸定制"],
          zjgml: ["北欧优质“芬兰松”"],
          zdcp: ["儿童床", "沙发", "衣柜"],
          wx: [],
          imgs: [
            require("../../assets/images/home/ziyouwangguo1.jpg"),
            require("../../assets/images/home/ziyouwangguo2.jpg"),
            require("../../assets/images/home/ziyouwangguo3.jpg"),
            require("../../assets/images/home/ziyouwangguo4.jpg"),
            require("../../assets/images/home/ziyouwangguo5.jpg")
          ],
          products: "床/沙发/书桌衣柜等",
          region: "中国，江西",
          area: "21943.99m",
          total: "8000万",
          prodEquip: "58台",
          type: "工厂",
          employee: "300余人",
          auth: "—",
          testEquip: "—/—",
          load: 0.96,
          nextMonthLoad: 0.91
        },
        {
          name: "江西康名箭家具有限公司",
          logo: require("../../assets/images/home/cloudWorks/kangmingjian/kangminjianlogo.png"),
          labels: ["环保认证", "水性漆", "独有工艺"],
          league: {
            logo: require("../../assets/images/home/leaguelogo.png"),
            name: "南康家具品牌联盟成员"
          },
          jgsx: ["自有品牌", "订单加工"],
          zjgml: ["橡木", "胡桃木", "乌金木"],
          zdcp: ["床", "门窗", "餐桌椅"],
          wx: [],
          imgs: [
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img1.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img2.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img3.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img4.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img5.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img7.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img8.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img9.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img10.jpg"),
            require("../../assets/images/home/cloudWorks/kangmingjian/kangmingjian_img12.jpg")
          ],
          products: "餐椅/桌子/床/门窗",
          region: "中国，江西",
          area: "6000m",
          total: "3100万",
          prodEquip: "60台",
          type: "工厂",
          employee: "95",
          auth: "—",
          testEquip: "—/—",
          load: 0.94,
          nextMonthLoad: 0.92
        },
        {
          name: "赣州市南康区百年嘉年华家具有限公司",
          logo: require("../../assets/images/home/bainianjiajianhualogo.png"),
          labels: ["环保认证", "低不良率", "独有工艺"],
          league: {
            logo: require("../../assets/images/home/leaguelogo.png"),
            name: "南康家具品牌联盟成员"
          },
          jgsx: ["自有品牌", "订单加工"],
          zjgml: ["美国橡木", "橡胶木"],
          zdcp: ["客厅系列", "卧室系列", "厨房系列"],
          wx: [],
          imgs: [
            require("../../assets/images/home/jianianhua_img1.jpg"),
            require("../../assets/images/home/jianianhua_img2.jpg"),
            require("../../assets/images/home/jianianhua_img3.jpg"),
            require("../../assets/images/home/jianianhua_img4.jpg")
          ],
          products: "餐椅/沙发/床",
          region: "中国，江西",
          area: "22000m",
          total: "3000万",
          prodEquip: "50台",
          type: "工厂",
          employee: "136",
          auth: "—",
          testEquip: "—/—",
          load: 0.97,
          nextMonthLoad: 0.93
        }
      ],
      load: []
    };
  },
  computed: {},
  beforeDestroy() {
    this.load.map(n => {
      n.dispose();
    });
  },
  mounted() {
    document.querySelectorAll(".load").forEach((n, i) => {
      let value = parseFloat(n.innerHTML);
      this.load[i] = echarts.init(n);

      let data1 = [value, value, value];
      let option = {
        backgroundColor: "#fff",
        // graphic: [{
        //     type: 'group',
        //     left: 'center',
        //     bottom: 1
        // }],
        series: [
          {
            type: "liquidFill",
            radius: "90%",
            center: ["50%", "50%"],
            data: data1,
            outline: {
              show: false
            },
            backgroundStyle: {
              borderWidth: 1,
              // borderColor: '#1daaeb',
              color: "#fff"
            },
            label: {
              normal: {
                formatter: (value * 100).toFixed(2) + "%",
                textStyle: {
                  fontSize: 10
                }
              }
            }
          }
        ]
      };
      this.load[i].setOption(option);
    });
  },
  methods: {
    randomInt(n) {
      return Math.floor(Math.random() * n + 1);
    },
    changeTab(i) {
      this.cpsc.activeIndex = i;
    },
    changeImgIndex(i, isNext = false) {
      if (isNext) {
        this.$refs.carousel[i].next();
        return;
      }
      this.$refs.carousel[i].prev();
    }
  }
};
</script>
<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto;
  margin-top: 28px;
  background: #fff;
  &:first-child {
    margin-top: 0;
  }
  h4 {
    font-size: 24px;
    color: #292929;
    padding: 31px 26px;
    line-height: 1;
    .circle {
      width: 12px;
      height: 12px;
      border: 3px solid #5074ee;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
}
.banner {
  height: 420px;
  .el-carousel {
    height: 100%;
  }
  .img {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: auto 100%;
  }
}
.block-1 {
  .item-main {
    padding: 17px 31px 31px 45px;
    .item-left {
      width: 140px;
      height: 510px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(169, 182, 225, 1);
      .tabs {
        margin-left: -1px;
        padding-top: 15px;
        .tab-item {
          width: 100px;
          height: 40px;
          background: rgba(169, 182, 225, 1);
          margin-bottom: 30px;
          color: #fff;
          padding-left: 20px;
          line-height: 40px;
          cursor: pointer;
          &.active {
            background: rgba(80, 116, 238, 1);
            box-shadow: 0px 2px 4px 0px rgba(169, 182, 225, 1);
          }
        }
      }
    }
    .item-right {
      width: 984px;
      height: 510px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(230, 230, 230, 1);
      border-left: 0;
      .tab-wrap {
        padding: 30px 0 0 30px;
        img {
          margin: 0 28px 30px 0;
          width: 210px;
          height: 210px;
        }
      }
    }
  }
}
.block-2 {
  padding-bottom: 27px;
  .table {
    margin: 0 30px;
    width: 1140px;
    text-align: center;
    color: #4d4d4d;
    .thead {
      .tr {
        display: flex;
        .th {
          flex: 1;
          height: 50px;
          background-color: #f8f9ff;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .tbody {
      border-left: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      box-shadow: 0px 2px 4px 0px rgba(209, 217, 226, 1);
      height: 483px;
      .seamless-warp {
        height: 483px;
        overflow: hidden;
      }
      .tr {
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        .td {
          padding: 20px 0;
          flex: 1;
          &:last-child {
            .td-inner {
              border-right: 0;
            }
          }
          .td-inner {
            border-right: 1px solid #e6e6e6;
            height: 80px;
            img {
              width: 80px;
              height: 80px;
              margin-right: 20px;
            }
            .name {
              width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-weight: bold;
            }
            .request-name {
              color: #292929;
              font-size: 16px;
              font-weight: bold;
            }
            .request-amount {
              font-size: 18px;
              font-weight: bold;
              text-align: left;
              width: 50px;
              margin: 0 auto;
              div {
                margin-top: 10px;
                color: #808080;
                font-weight: 500;
                font-size: 14px;
              }
            }
            .time {
              color: #808080;
              font-weight: 500;
            }
            .price {
              color: #4d4d4d;
              font-size: 18px;
              font-weight: bold;
              text-align: left;
              width: 180px;
              margin: 0 auto;
              div {
                margin-top: 10px;
                color: #808080;
                font-size: 14px;
                font-weight: 500;
              }
            }
            .btn {
              width: 76px;
              height: 32px;
              line-height: 30px;
              border-radius: 16px;
              border: 1px solid rgba(80, 116, 238, 1);
              color: #5074ee;
              text-align: center;
              cursor: pointer;
              transition: all 0.3s;
              &:hover {
                background: rgba(80, 116, 238, 1);
                box-shadow: 0px 2px 4px 0px rgba(169, 182, 225, 1);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
.block-3 {
  .top-bar {
    padding: 20px;
    box-shadow: 0px 2px 4px 0px rgba(209, 217, 226, 1);
    .el-select {
      width: 240px;
    }
    .el-input {
      width: 500px;
    }
  }
  .list {
    padding: 20px 20px 35px;
    .item-block {
      border: 1px solid rgba(230, 230, 230, 1);
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
      .item-top {
        height: 120px;
        padding: 20px 15px;
        > div:first-child {
          img {
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }
          .img-right {
            .name {
              color: #292929;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 20px;
            }
            .label-wrap {
              label {
                padding: 0 9px;
                height: 18px;
                border-radius: 9px;
                min-width: 50px;
                font-size: 8px;
                line-height: 18px;
                margin-right: 10px;
                &.color-1 {
                  background: rgba(86, 129, 210, 0.2);
                  color: #5681d2;
                }
                &.color-2 {
                  background: rgba(75, 187, 165, 0.2);
                  color: #4bbba5;
                }
                &.color-3 {
                  background: rgba(144, 59, 204, 0.2);
                  color: #903bcc;
                }
                &.color-4 {
                  background: rgba(246, 111, 106, 0.2);
                  color: #f66f6a;
                }
                &.color-5 {
                  background: rgba(54, 155, 233, 0.2);
                  color: #369be9;
                }
              }
            }
          }
        }
        > div:last-child {
          color: #4d4d4d;
          font-weight: bold;
          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
        }
      }
      .item-mid {
        border-top: 1px solid rgba(230, 230, 230, 1);
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        .feature-item {
          padding: 0 50px;
          border-right: 1px solid #e6e6e6;
          margin: 10px;
          &:last-child {
            border-right: none;
          }
          &:first-child {
            padding-left: 20px;
          }
          .name {
            color: #4d4d4d;
            font-weight: bold;
            line-height: 1;
            margin-right: 10px;
          }
          .label-wrap {
            label {
              padding: 0 9px;
              min-width: 50px;
              height: 18px;
              line-height: 16px;
              border-radius: 9px;
              border: 1px solid rgba(169, 182, 225, 1);
              color: #a9b6e1;
              font-size: 8px;
              margin-left: 10px;
              text-align: center;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
      .item-btm {
        height: 240px;
        padding: 20px;
        .img-wrap {
          width: 200px;
          height: 200px;
          position: relative;
          .prev,
          .next {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            position: absolute;
            top: 50%;
            margin-top: -18px;
            box-shadow: 0 0 6px #ccc;
            background-color: #fff;
            font-size: 22px;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
            font-weight: bold;
            z-index: 2;
          }
          .prev {
            left: -10px;
          }
          .next {
            right: -10px;
          }
          .el-carousel {
            height: 100%;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .table {
          border-top: 1px solid rgba(219, 225, 246, 1);
          border-left: 1px solid rgba(219, 225, 246, 1);
          box-sizing: border-box;
          margin-left: 20px;
          > div {
            width: 600px;
            &:first-child {
              > div:nth-child(2n) {
                width: 500px;
              }
            }
            > div {
              width: 100px;
              height: 40px;
              line-height: 38px;
              background-color: #f8f9ff;
              padding-left: 20px;
              color: #4d4d4d;
              font-weight: bold;
              border-bottom: 1px solid rgba(219, 225, 246, 1);
              border-right: 1px solid rgba(219, 225, 246, 1);
            }
            > div:nth-child(2n) {
              background-color: #fff;
              width: 200px;
              color: #808080;
            }
          }
        }
        .load-wrap {
          .load-item {
            width: 140px;
            padding: 18px 0;
            border: 1px solid rgba(219, 225, 246, 1);
            margin-left: 8px;
            text-align: center;
            .tit {
              margin-bottom: 30px;
              color: #4d4d4d;
              font-weight: bold;
            }
            .load {
              width: 96px;
              height: 96px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
</style>
